import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { FaRegHandshake, FaToolbox, FaLayerGroup, FaPaintBrush } from "react-icons/fa";

const Home: React.FC = () => {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Elevate Your Brand with Kōrero Social</h1>
        <p>Crafting Tailored Social Media Strategies for Unparalleled Growth</p>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="service-icon">
              <FaRegHandshake />
            </div>
            <h3>Social Media Strategy</h3>
            <p>Our team will take care of your social media, creating engaging content and managing your online presence to help you connect with your audience effectively.</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaToolbox />
            </div>
            <h3>DIY Tools</h3>
            <p>Access our easy-to-use tools designed to help you grow your social media organically. Perfect for brands looking to take control of their digital journey!</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaLayerGroup />
            </div>
            <h3>Hybrid Solutions</h3>
            <p>Not sure what you need? We offer a combination of management and DIY tools to create a customised strategy that fits your goals.</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaPaintBrush />
            </div>
            <h3>Complete Brand Refreshes</h3>
            <p>Revitalise your brand with our comprehensive refresh services, from reimagining your brand colours to crafting new logos and more.</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <h2>Book a Free Consultation</h2>
        <Link to="/book-consultation" className="cta-button">Get Started</Link>
      </section>

      {/* Welcome Pack Section */}
      <section className="welcome-pack-section">
        <h2>Download our Welcome Pack</h2>
        <p>Get started with Kōrero Social by downloading our comprehensive Welcome Pack. It includes everything you need to know about our services and how we can help elevate your brand.</p>
        <a 
          href="https://korerosocial.blob.core.windows.net/publiccontent/Kōrero Social Welcome Packet.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="download-button"
        >
          Download Now
        </a>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>Testimonials</h2>
        <div className="testimonial">
          <p>"Working with Bella transformed our brand's online presence—she crafted a unique colour palette, revitalised our social media, and built a cohesive strategy that truly reflects who we are. Her creativity and expertise have been invaluable in helping StepFWD grow!"</p>
          <cite>- StepFWD</cite>
        </div>
      </section>
    </div>
  );
};

export default Home;

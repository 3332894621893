import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPost.css';

const BlogPostTwoPage: React.FC = () => {
  const currentUrl = window.location.href;
  const title = "The Importance of Authenticity in Social Media Marketing";
  const summary = "Learn why authentic content is crucial for building lasting connections with your audience in today's digital landscape.";
  const source = "Kōrero Social";

  const shareOnLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(source)}`;
    window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(title)}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="blog-post-page">
      <article className="blog-post">
        <div className="blog-post-header">
          <span className="blog-category">Social Media Strategy</span>
          <h1>The Importance of Authenticity in Social Media Marketing</h1>
          <div className="blog-meta">
            <span className="read-time">8 min read</span>
          </div>
        </div>

        <div className="blog-content">
          <p>In today's fast-paced digital world, social media has become the central hub for brands to connect with their audience. While it offers unparalleled opportunities for growth, it also presents a unique challenge: standing out in a sea of content. For brands looking to build lasting relationships and foster trust, authenticity is the key ingredient.</p>
          
          <p>At Kōrero Social, authenticity isn't just a buzzword—it's at the heart of what we do. Here's why it's essential for success in social media marketing.</p>
          
          <h2>Why Authenticity Matters</h2>
          <p>With so much content bombarding users daily, people are becoming increasingly savvy about the messages they consume. Gone are the days when overly polished and scripted content ruled. Today, audiences crave real, relatable, and genuine interactions. Authenticity builds trust, and trust is what ultimately drives engagement, loyalty, and conversions.</p>
          
          <p>In a world where algorithms control what we see, authentic content stands out. It resonates on a human level, cutting through the noise and creating meaningful connections between brands and their audiences.</p>
          
          <h2>What Does Authenticity Look Like?</h2>
          <p>For many brands, authenticity can feel like an abstract concept. But it's actually about being true to your brand's values, mission, and audience. At its core, authenticity is about transparency and showing the real side of your brand.</p>
          
          <ul>
            <li><strong>Showcase your journey:</strong> People want to know the story behind your brand. Whether you're a startup or a well-established business, sharing your challenges and successes creates relatability. For us at Kōrero Social, being a wahine Māori-led business is a key part of our identity. We embrace our heritage and infuse it into our work, making it part of our story.</li>
            <li><strong>Engage with your audience:</strong> Responding to comments, answering questions, and acknowledging feedback shows that you're listening. It's a two-way conversation that creates a sense of community around your brand.</li>
            <li><strong>Stay true to your voice:</strong> Every brand has a unique voice. Whether it's playful and light-hearted or more formal, staying consistent with your tone helps build recognition and trust.</li>
          </ul>

          <h2>The Risks of Inauthenticity</h2>
          <p>While it's easy to see the benefits of authenticity, the risks of being inauthentic are equally important to consider. When brands stray too far from their values or adopt a disingenuous tone, audiences notice. Inauthenticity can quickly lead to distrust, which is difficult to rebuild once lost.</p>

          <h2>How to Build Authenticity on Social Media</h2>
          <ol>
            <li><strong>Tell your story:</strong> Share the origin of your brand, your values, and what drives you.</li>
            <li><strong>Be consistent:</strong> Stay true to your brand's voice across all platforms.</li>
            <li><strong>Embrace transparency:</strong> Share behind-the-scenes moments and show the people behind the brand.</li>
            <li><strong>Prioritise quality over quantity:</strong> Focus on creating high-quality, meaningful content that aligns with your brand's values.</li>
          </ol>

          <h2>The Kōrero Social Approach</h2>
          <p>At Kōrero Social, authenticity is woven into the fabric of our strategies. We help brands build genuine connections with their audience by crafting content that reflects their unique voice and mission. Our goal isn't just to increase followers or likes—it's to foster meaningful engagement that leads to long-term growth.</p>

          <h2>Conclusion</h2>
          <p>In a world where attention is fleeting, authenticity is what sets brands apart. It builds trust, fosters community, and drives lasting success. At Kōrero Social, we believe that social media should be a space where brands can truly connect with their audience in a meaningful way.</p>
          
          <p>At Kōrero Social, we live by our motto: Kōrero. Connect. Grow. Let's start a conversation, build authentic connections, and watch your brand flourish.</p>
        </div>

        <div className="blog-footer">
          <Link to="/blog" className="back-to-blog">← Back to Blog</Link>
          <div className="share-buttons">
            <button onClick={shareOnLinkedIn} className="share-button">
              Share on LinkedIn
            </button>
            <button onClick={shareOnTwitter} className="share-button">
              Share on Twitter
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BlogPostTwoPage; 
import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <main style={mainStyle}>
      <h2 style={headerStyle}>Privacy Policy for Kōrero Social</h2>
      <section style={sectionStyle}>
        <h3 style={subHeaderStyle}>1. Introduction</h3>
        <p style={paragraphStyle}>
          Kōrero Social values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, store, and protect information gathered through our website and services.
        </p>
        <h3 style={subHeaderStyle}>2. Information We Collect</h3>
        <p style={paragraphStyle}>
          We may collect personal information you directly provide, such as:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>
            <strong>Contact Details:</strong> Name, email address, phone number, and company name when you make an enquiry or subscribe to updates.
          </li>
          <li style={paragraphStyle}>
            <strong>Usage Information:</strong> Data on how you interact with our website (e.g., IP address, device details, and pages visited) through cookies and similar technologies.
          </li>
        </ul>
        <h3 style={subHeaderStyle}>3. How We Use Your Information</h3>
        <p style={paragraphStyle}>
          We use your information to:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>Respond to your enquiries and provide our services</li>
          <li style={paragraphStyle}>Improve our website, services, and user experience</li>
          <li style={paragraphStyle}>Send you relevant updates if you’ve opted in (such as newsletters or blog updates)</li>
          <li style={paragraphStyle}>Comply with legal obligations and protect our rights</li>
        </ul>
        <h3 style={subHeaderStyle}>4. Cookies and Tracking Technologies</h3>
        <p style={paragraphStyle}>
          Our website may use cookies and other tracking technologies to collect and store information about your interaction with our site. You can manage your cookie preferences through your browser settings.
        </p>
        <h3 style={subHeaderStyle}>5. How We Share Your Information</h3>
        <p style={paragraphStyle}>
          We do not sell or rent your personal information. However, we may share your information with trusted third-party service providers who assist in operating our website and services (e.g., analytics providers), provided they agree to keep your information secure and confidential.
        </p>
        <h3 style={subHeaderStyle}>6. Your Rights and Choices</h3>
        <p style={paragraphStyle}>
          You have the right to:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>Access, update, or delete your personal information</li>
          <li style={paragraphStyle}>Opt out of receiving marketing emails by following the unsubscribe link</li>
          <li style={paragraphStyle}>Request further information on how we handle your data</li>
        </ul>
        <h3 style={subHeaderStyle}>7. Data Security</h3>
        <p style={paragraphStyle}>
          We take appropriate security measures to protect your information from unauthorised access, alteration, or loss. While we strive to secure your data, we cannot guarantee its absolute security.
        </p>
        <h3 style={subHeaderStyle}>8. Third-Party Links</h3>
        <p style={paragraphStyle}>
          Our website may include links to third-party sites. This policy does not apply to those sites, so please review their privacy policies directly.
        </p>
        <h3 style={subHeaderStyle}>9. Policy Updates</h3>
        <p style={paragraphStyle}>
          We may update this Privacy Policy from time to time. Changes will be posted on this page, with the “Last Updated” date.
        </p>
        <h3 style={subHeaderStyle}>10. Contact Us</h3>
        <p style={paragraphStyle}>
          For questions or requests regarding this Privacy Policy, please contact us at:
        </p>
        <p style={paragraphStyle}>
          Email: <a href="mailto:kiaora@kōrerosocial.com" style={linkStyle}>kiaora@kōrerosocial.com</a>
        </p>
      </section>
    </main>
  );
}

const mainStyle: React.CSSProperties = {
  padding: '20px',
  margin: '20px auto',
  maxWidth: '800px',
  textAlign: 'center',
};

const headerStyle: React.CSSProperties = {
  fontSize: '2.5rem',
  color: '#4A4E4D',
  marginBottom: '20px',
};

const subHeaderStyle: React.CSSProperties = {
  fontSize: '1.8rem',
  color: '#4A4E4D',
  marginTop: '20px',
  marginBottom: '10px',
};

const paragraphStyle: React.CSSProperties = {
  fontSize: '1.2rem',
  color: '#4A4E4D',
  marginBottom: '15px',
};

const listStyle: React.CSSProperties = {
  textAlign: 'left',
  paddingLeft: '20px',
};

const linkStyle: React.CSSProperties = {
  color: '#4A4E4D',
  textDecoration: 'underline',
};

const sectionStyle: React.CSSProperties = {
  textAlign: 'left',
  padding: '0 20px',
};

export default PrivacyPolicyPage;
import React from 'react';
import { FaRegHandshake, FaToolbox, FaLayerGroup, FaPaintBrush } from "react-icons/fa";
import './Services.css';

const ServicesPage: React.FC = () => {
  return (
    <div className="services-page">
      <section className="services-content">
        <h1>Our Services</h1>
        <div className="services-grid">
          <div className="service-card">
            <div className="service-icon">
              <FaRegHandshake />
            </div>
            <h3>Social Media Strategy</h3>
            <p>Our team will take care of your social media, creating engaging content and managing your online presence to help you connect with your audience effectively.</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaToolbox />
            </div>
            <h3>DIY Tools</h3>
            <p>Access our easy-to-use tools designed to help you grow your social media organically. Perfect for brands looking to take control of their digital journey!</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaLayerGroup />
            </div>
            <h3>Hybrid Solutions</h3>
            <p>Not sure what you need? We offer a combination of management and DIY tools to create a customised strategy that fits your goals.</p>
          </div>

          <div className="service-card">
            <div className="service-icon">
              <FaPaintBrush />
            </div>
            <h3>Complete Brand Refreshes</h3>
            <p>Revitalise your brand with our comprehensive refresh services, from reimagining your brand colours to crafting new logos and more.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;

import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPage.css';

const BlogPage: React.FC = () => {
  const blogPosts = [
    {
      id: 1,
      title: "How Kōrero Social Started: Our Journey in Social Media",
      excerpt: "Discover the story behind Kōrero Social and how we've grown to become a leading voice in social media strategy.",
      readTime: "10 min read",
      path: "/blog-post-one",
      category: "Company Story"
    },
    {
      id: 2,
      title: "The Importance of Authenticity in Social Media Marketing",
      excerpt: "Learn why authentic content is crucial for building lasting connections with your audience in today's digital landscape.",
      readTime: "8 min read",
      path: "/blog-post-two",
      category: "Social Media Strategy"
    }
  ];

  return (
    <div className="blog-page">
      <section className="blog-hero">
        <div className="blog-hero-content">
          <h1>Empowering Your Brand: Our Blog</h1>
          <p>Discover the latest trends, tips, and insights in social media strategy.</p>
        </div>
      </section>

      <section className="blog-content">
        <div className="blog-grid">
          {blogPosts.map(post => (
            <Link to={post.path} className="blog-card" key={post.id}>
              <div className="blog-card-content">
                <div className="blog-meta">
                  <span className="blog-category">{post.category}</span>
                </div>
                <h2>{post.title}</h2>
                <p>{post.excerpt}</p>
                <div className="blog-footer">
                  <span className="read-time">{post.readTime}</span>
                  <span className="read-more">Read More →</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
}

export default BlogPage;

import React from 'react';

const TermsOfServicePage: React.FC = () => {
  return (
    <main style={mainStyle}>
      <h2 style={headerStyle}>Terms of Service for Kōrero Social</h2>
      <section style={sectionStyle}>
        <h3 style={subHeaderStyle}>1. Introduction</h3>
        <p style={paragraphStyle}>
          Welcome to Kōrero Social. By accessing or using our website and services, you agree to comply with and be bound by these Terms of Service (“Terms”). Please read these Terms carefully before engaging with our services.
        </p>
        <h3 style={subHeaderStyle}>2. Services Provided</h3>
        <p style={paragraphStyle}>
          Kōrero Social provides social media growth strategies, brand consultancy, content creation, and related digital services. The details, scope, and pricing of each service will be specified in individual service agreements or proposals.
        </p>
        <h3 style={subHeaderStyle}>3. Acceptance of Terms</h3>
        <p style={paragraphStyle}>
          By using our website or services, you confirm that you are at least 18 years of age and legally capable of entering into a binding agreement. If you do not agree with any part of these Terms, please discontinue use of our services.
        </p>
        <h3 style={subHeaderStyle}>4. User Obligations</h3>
        <p style={paragraphStyle}>
          You agree to:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>Use our services in compliance with these Terms and applicable laws.</li>
          <li style={paragraphStyle}>Provide accurate information when requested (e.g., in registration forms or service agreements).</li>
          <li style={paragraphStyle}>Respect intellectual property rights and not to copy, distribute, or misuse any materials provided by Kōrero Social without permission.</li>
        </ul>
        <h3 style={subHeaderStyle}>5. Intellectual Property</h3>
        <p style={paragraphStyle}>
          All content, branding, and materials provided by Kōrero Social, including but not limited to logos, website content, strategies, and digital assets, are the property of Kōrero Social. You may not use, reproduce, or redistribute any content without prior written consent.
        </p>
        <h3 style={subHeaderStyle}>6. Payments and Fees</h3>
        <ul style={listStyle}>
          <li style={paragraphStyle}>Payment terms for each service will be outlined in your service agreement or invoice.</li>
          <li style={paragraphStyle}>Payments must be made in full by the due date specified. Failure to pay on time may result in suspension of services until payment is received.</li>
          <li style={paragraphStyle}>Kōrero Social reserves the right to adjust fees and pricing. Any fee changes will be communicated in advance and will not affect existing service agreements.</li>
        </ul>
        <h3 style={subHeaderStyle}>7. Confidentiality</h3>
        <p style={paragraphStyle}>
          Both parties agree to maintain confidentiality regarding any private information disclosed during the term of our agreement, including but not limited to business strategies, client details, and project plans. This obligation of confidentiality will survive the termination of our agreement.
        </p>
        <h3 style={subHeaderStyle}>8. Limitation of Liability</h3>
        <p style={paragraphStyle}>
          Kōrero Social strives to provide accurate and effective services; however, we make no warranties or guarantees regarding outcomes. To the extent permitted by law, Kōrero Social will not be held liable for any indirect, incidental, or consequential damages arising from the use of our services.
        </p>
        <h3 style={subHeaderStyle}>9. Termination of Services</h3>
        <p style={paragraphStyle}>
          Either party may terminate the service agreement with written notice. Upon termination, all outstanding payments will become due, and any work completed to that point will be delivered as applicable. Kōrero Social reserves the right to terminate services if you breach these Terms or engage in conduct that may harm our reputation.
        </p>
        <h3 style={subHeaderStyle}>10. Changes to Terms</h3>
        <p style={paragraphStyle}>
          We may update these Terms from time to time. Changes will be posted on this page with the “Last Updated” date, and continued use of our services after changes constitutes acceptance of the new Terms.
        </p>
        <h3 style={subHeaderStyle}>11. Governing Law</h3>
        <p style={paragraphStyle}>
          These Terms are governed by the laws of New Zealand. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of New Zealand courts.
        </p>
        <h3 style={subHeaderStyle}>12. Contact Us</h3>
        <p style={paragraphStyle}>
          If you have questions regarding these Terms, please contact us at:
        </p>
        <p style={paragraphStyle}>
          Email: <a href="mailto:kiaora@kōrerosocial.com" style={linkStyle}>kiaora@kōrerosocial.com</a>
        </p>
      </section>
    </main>
  );
}

const mainStyle: React.CSSProperties = {
  padding: '20px',
  margin: '20px auto',
  maxWidth: '800px',
  textAlign: 'center',
};

const headerStyle: React.CSSProperties = {
  fontSize: '2.5rem',
  color: '#4A4E4D',
  marginBottom: '20px',
};

const subHeaderStyle: React.CSSProperties = {
  fontSize: '1.8rem',
  color: '#4A4E4D',
  marginTop: '20px',
  marginBottom: '10px',
};

const paragraphStyle: React.CSSProperties = {
  fontSize: '1.2rem',
  color: '#4A4E4D',
  marginBottom: '15px',
};

const listStyle: React.CSSProperties = {
  textAlign: 'left',
  paddingLeft: '20px',
};

const linkStyle: React.CSSProperties = {
  color: '#4A4E4D',
  textDecoration: 'underline',
};

const sectionStyle: React.CSSProperties = {
  textAlign: 'left',
  padding: '0 20px',
};

export default TermsOfServicePage;
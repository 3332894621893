import React, { useEffect } from 'react';

const ConsultationBookingPage: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div style={pageStyle}>
      <section style={contentStyle}>
        <h2 style={headerStyle}>Book a Free Consultation</h2>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/korerosocial/30min?text_color=4a4e4d&primary_color=9ba89e"
          style={calendlyStyle}
        ></div>
      </section>
    </div>
  );
}

export default ConsultationBookingPage;

const pageStyle: React.CSSProperties = {
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '2rem'
};

const contentStyle: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  borderRadius: '15px',
  padding: '3rem',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  textAlign: 'center'
};

const headerStyle: React.CSSProperties = {
  fontSize: '2.5rem',
  color: '#4A4E4D',
  marginBottom: '20px'
};

const calendlyStyle: React.CSSProperties = {
  minWidth: '320px',
  height: '700px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  overflow: 'hidden'
};
  
import React from 'react';
import { FaHeart, FaLightbulb, FaHandshake, FaChartLine } from 'react-icons/fa';
import './AboutPage.css';

const AboutPage: React.FC = () => {
  const values = [
    {
      icon: <FaHeart />,
      title: "Authenticity",
      description: "We believe in genuine connections and authentic brand representation."
    },
    {
      icon: <FaLightbulb />,
      title: "Innovation",
      description: "Constantly evolving and adapting to the dynamic digital landscape."
    },
    {
      icon: <FaHandshake />,
      title: "Partnership",
      description: "Working together to achieve your social media goals."
    },
    {
      icon: <FaChartLine />,
      title: "Growth",
      description: "Committed to driving meaningful results and sustainable growth."
    }
  ];

  return (
    <div className="about-page">
      {/* Main About Section */}
      <section className="about-content">
        <h1>About Us</h1>
        <div className="about-text">
          <p>Welcome to Kōrero Social! We are dedicated to helping brands connect with their audiences through innovative social media strategies. Our team of experts is passionate about crafting tailored solutions that drive engagement and growth.</p>
          
          {/* Mission Section */}
          <div className="mission-section">
            <h2>Our Mission</h2>
            <p>At Kōrero Social, our mission is to empower brands to thrive in the digital space by fostering authentic relationships through innovative social media strategies. We focus on understanding each client's unique voice and values, creating tailored solutions that resonate with their target audiences.</p>
            
            <p>By combining cultural insight with cutting-edge tools, we enable businesses to navigate the ever-evolving digital landscape effectively. We believe in the power of storytelling and connection, striving to help our clients not just meet their goals but exceed them through meaningful engagement that drives results.</p>
          </div>

          {/* Values Grid */}
          <div className="values-section">
            <h2>Our Values</h2>
            <div className="values-grid">
              {values.map((value, index) => (
                <div key={index} className="value-card">
                  <div className="value-icon">
                    {value.icon}
                  </div>
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;

import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPost.css';

const BlogPostOnePage: React.FC = () => {
  const currentUrl = window.location.href;
  const title = "How Kōrero Social Started: Our Journey in Social Media";
  const summary = "Discover the story behind Kōrero Social and how we've grown to become a leading voice in social media strategy.";
  const source = "Kōrero Social";

  const shareOnLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(source)}`;
    window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(title)}`;
    window.open(twitterUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="blog-post-page">
      <article className="blog-post">
        <div className="blog-post-header">
          <span className="blog-category">Company Story</span>
          <h1>How Kōrero Social Started: Our Journey in Social Media</h1>
          <div className="blog-meta">
            <span className="read-time">10 min read</span>
          </div>
        </div>

        <div className="blog-content">
          <p>Kōrero Social's story is deeply intertwined with my personal journey as a wahine Māori and my passion for storytelling in the digital age. In te ao Māori, kōrero is not just about speaking; it's about connection, communication, and creating relationships. This is the foundation upon which Kōrero Social was built — a desire to help businesses find their voice in the vast and ever-changing social media landscape by building authentic relationships with their audiences.</p>

          <h2>Drawing from My Background</h2>
          <p>Though I didn't start out directly working in social media, my career in sales, lead generation, and organic business growth across various sectors laid the groundwork for what Kōrero Social would become. I've spent years helping businesses grow, not just by focusing on numbers but by understanding the essence of their brand and building genuine connections with their customers.</p>
          
          <p>This experience taught me that every business has its own unique voice, and it's important to convey that voice in a way that resonates with its audience. With that knowledge, I realised that social media was a natural extension of my work.</p>

          <h2>Why "Kōrero"?</h2>
          <p>The name "Kōrero Social" reflects the essence of what we do. In te ao Māori, "kōrero" means conversation or dialogue, but it holds deeper meaning than just speaking words. Kōrero is about exchanging ideas, building understanding, and creating relationships — all core aspects of what I wanted this business to represent.</p>
          
          <p>In today's world, social media is where most of these conversations happen. It's where businesses can share their stories, engage with their communities, and grow their presence.</p>

          <h2>Creating Our Brand</h2>
          <p>When it came to creating Kōrero Social, I wanted to develop a brand that reflected our playful yet professional approach. We spent time crafting a logo that symbolised both connection and growth, blending pastel tones with the koru symbol to represent new beginnings and the ongoing process of learning and adapting.</p>
          
          <p>The colour palette of soft pinks and purples represents our youthful, fresh vibe, while maintaining a sense of professionalism. The typography we chose is modern but approachable, showing that we are here to help brands in a way that feels personal and supportive.</p>

          <h2>Our Unique Approach to Social Media Growth</h2>
          <p>At Kōrero Social, we take a holistic approach to social media strategy. Drawing on my background in business growth, we understand that social media is not a standalone tool; it's a critical component of an overall growth strategy. Our approach is different from traditional agencies — we don't just churn out content for the sake of posting. Instead, we focus on creating conversations that matter.</p>
          
          <p>Our process is simple yet effective. First, we take the time to understand our clients' unique voices. We learn about their brand values, their customers, and their goals. From there, we craft social media strategies that feel authentic to who they are and engage their audience in a way that feels natural.</p>

          <h2>Starting Small, Thinking Big</h2>
          <p>When Kōrero Social launched, it started as a small, passion-driven project. We didn't have a huge client base or a massive following, but we had a clear vision. We wanted to create a space where businesses, especially smaller ones, could flourish online by staying true to their core values.</p>

          <h2>Looking Ahead</h2>
          <p>Our journey is just beginning. The world of social media is constantly changing, and we are committed to staying at the forefront of those changes, offering our clients innovative, authentic ways to connect with their audiences. As Kōrero Social grows, we remain grounded in the values that inspired us from the start: whanaungatanga (relationships), manaakitanga (care for others), and kotahitanga (unity).</p>
          
          <p>Kōrero Social is more than just a social media strategy company — we're here to help businesses build lasting connections and achieve sustainable growth. We're excited to continue this journey, and we can't wait to see where it takes us.</p>
          
          <p>At Kōrero Social, our goal is simple: Kōrero. Connect. Grow.</p>
        </div>

        <div className="blog-footer">
          <Link to="/blog" className="back-to-blog">← Back to Blog</Link>
          <div className="share-buttons">
            <button onClick={shareOnLinkedIn} className="share-button">
              Share on LinkedIn
            </button>
            <button onClick={shareOnTwitter} className="share-button">
              Share on Twitter
            </button>
          </div>
        </div>
      </article>
    </div>
  );
};

export default BlogPostOnePage;

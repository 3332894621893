import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FaFacebook, FaTiktok, FaInstagram, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const legalLinks = [
    { path: '/privacy-policy', label: 'Privacy Policy' },
    { path: '/terms-of-service', label: 'Terms of Service' },
    { path: '/faqs', label: 'FAQs' }
  ];

  const socialLinks = [
    { icon: <FaFacebook />, url: 'https://www.facebook.com/people/Kōrero-Social/61568317199137', label: 'Facebook' },
    { icon: <FaInstagram />, url: 'https://www.instagram.com/korerosocial', label: 'Instagram' },
    { icon: <FaTiktok />, url: 'https://www.tiktok.com/@korero.social', label: 'TikTok' },
    { icon: <FaLinkedin />, url: 'https://www.linkedin.com/company/kōrero-social', label: 'LinkedIn' },
    { icon: <FaTwitter />, url: 'https://x.com/KoreroSocial', label: 'Twitter' }
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-brand">
            <div className="social-links">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={social.label}
                  className="social-icon"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>

          <div className="footer-contact">
            <a href="mailto:kiaora@kōrerosocial.com" className="footer-email">
              <FaEnvelope />
              <span>kiaora@kōrerosocial.com</span>
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-separator"></div>
          <div className="footer-info">
            <div className="legal-links">
              {legalLinks.map((link) => (
                <Link key={link.path} to={link.path}>{link.label}</Link>
              ))}
            </div>
            <p className="copyright">
              © {currentYear} Kōrero Social. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

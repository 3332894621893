import React, { useState } from 'react';
import './ContactPage.css';

const ContactPage: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://formspree.io/f/mrbgkbzr', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setIsSubmitted(true);
        form.reset();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="contact-page">
      <section className="contact-content">
        <h1>Ready to Elevate Your Brand?</h1>
        <p>Get in touch for a free consultation and let's start your journey to social media success.</p>
        {isSubmitted ? (
          <div className="success-message">
            <h2>Message Sent!</h2>
            <p>Thank you for reaching out. We'll be in touch with you soon.</p>
          </div>
        ) : (
          <form 
            id="contact-form"
            onSubmit={handleSubmit}
          >
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <textarea name="message" placeholder="Your Message" required></textarea>
            <button type="submit" className="cta-button">Send Message</button>
          </form>
        )}
      </section>
    </div>
  );
}

export default ContactPage;

import React, { useState } from 'react';

const FAQsPage: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What services does Kōrero Social offer?",
      answer: "At Kōrero Social, we specialise in social media strategy, content creation, brand growth, and authentic audience engagement. We tailor each service to help your brand connect meaningfully with its audience, both online and offline."
    },
    {
      question: "How does Kōrero Social approach social media strategy?",
      answer: "Our approach is centred on authenticity and genuine engagement. We craft each strategy to reflect your brand’s unique values and story, incorporating Māori influence and a focus on lasting relationships rather than quick wins."
    },
    {
      question: "Who is Kōrero Social's ideal client?",
      answer: "We love working with small businesses, startups, and brands that are looking for genuine, sustainable growth. Our clients value authentic connections with their audience and understand the power of meaningful engagement."
    },
    {
      question: "How does Kōrero Social integrate authenticity into social media marketing?",
      answer: "We believe authenticity is key to building trust. Our strategies are personalised, designed to showcase your brand’s real side, and focus on fostering long-term connections rather than just numbers."
    },
    {
      question: "Can Kōrero Social help with social media management?",
      answer: "Yes, we offer both strategy guidance and social media management. Whether you’re looking for a full-service option or guidance to manage it yourself, we can create a plan that best suits your needs."
    },
    {
      question: "What social media platforms does Kōrero Social specialise in?",
      answer: "We work with all the major platforms, including Instagram, TikTok, LinkedIn, and Facebook. We focus on platforms that align best with your brand and goals, ensuring your message reaches the right audience."
    },
    {
      question: "How long does it take to see results from a social media strategy?",
      answer: "Results can vary, but you’ll generally start to see meaningful engagement within the first 3 to 6 months. Our focus is on sustainable growth, so our strategies are designed to build lasting connections over time."
    },
    {
      question: "Do I need a large following to work with Kōrero Social?",
      answer: "Not at all! We work with brands of all sizes. Our strategies focus on quality engagement and authentic growth, which don’t rely on having a large following to begin with."
    },
    {
      question: "What’s the process for getting started with Kōrero Social?",
      answer: "Getting started is easy! We begin with a consultation to understand your brand, goals, and audience. From there, we create a tailored strategy and work alongside you to bring your vision to life."
    },
    {
      question: "Why choose Kōrero Social over other agencies?",
      answer: "Kōrero Social is a wahine Māori-led business with a commitment to authenticity and cultural depth. We’re dedicated to helping brands connect meaningfully with their audience, fostering genuine engagement and long-term success."
    }
  ];

  return (
    <main style={mainStyle}>
      <div style={contentBoxStyle}>
        <h2 style={headerStyle}>Frequently Asked Questions</h2>
        <div style={faqContainerStyle}>
          {faqs.map((faq, index) => (
            <div key={index} style={faqItemStyle}>
              <h3 style={questionStyle} onClick={() => toggleAnswer(index)}>
                + {faq.question}
              </h3>
              {openIndex === index && (
                <p style={answerStyle}>
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div style={buttonContainerStyle}>
        <a href="/book-consultation" className="button-common" style={commonButtonStyle}>
          Book a Free Consultation
        </a>
      </div>
    </main>
  );
}

const mainStyle: React.CSSProperties = {
  padding: '20px',
  margin: '20px auto',
  maxWidth: '800px',
  textAlign: 'center',
};

const contentBoxStyle: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.65)', // White with 35% transparency
  padding: '20px',
  borderRadius: '15px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headerStyle: React.CSSProperties = {
  fontSize: '2.5rem',
  color: '#4A4E4D',
  marginBottom: '20px',
};

const faqContainerStyle: React.CSSProperties = {
  textAlign: 'left',
};

const faqItemStyle: React.CSSProperties = {
  marginBottom: '20px',
  cursor: 'pointer',
};

const questionStyle: React.CSSProperties = {
  fontSize: '1.5rem',
  color: '#4A4E4D',
  marginBottom: '10px',
};

const answerStyle: React.CSSProperties = {
  fontSize: '1.2rem',
  color: '#4A4E4D',
  marginTop: '10px',
};

const buttonContainerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
};

const commonButtonStyle: React.CSSProperties = {
  background: 'linear-gradient(45deg, #005B8C, #0077BE)',
  color: '#FFFFFF',
  borderRadius: '12px',
  padding: '15px 25px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '1.5rem',
  transition: 'background 0.3s',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

export default FAQsPage;


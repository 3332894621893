import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import ServicesPage from './components/ServicesPage';
import Footer from './components/Footer';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import BlogPage from './components/BlogPage';
import BlogPostOnePage from './components/BlogPostOnePage';
import BlogPostTwoPage from './components/BlogPostTwoPage';
import FAQsPage from './components/FAQsPage';
import ConsultationBookingPage from './components/ConsultationBookingPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsOfServicePage from './components/TermsOfServicePage';
import './styles/responsive.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog-post-one" element={<BlogPostOnePage />} />
            <Route path="/blog-post-two" element={<BlogPostTwoPage />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/book-consultation" element={<ConsultationBookingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
